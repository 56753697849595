import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export default function Contact() {
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };


    return (
    <Box>
        <Typography sx={{fontSize: "1.5rem", fontWeight: "500", mt: "1rem"}}>Contact</Typography>

        <FormControl fullWidth sx={{mt: "16px"}}>
            <InputLabel id="demo-simple-select-label">Title</InputLabel>
            <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            label="Title"
            onChange={handleChange}
            >
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={30}>Thirty</MenuItem>
            </Select>
        </FormControl>

        <Box sx={{display: "flex", flexDirection: "row", alignItems:"center"}}>
            <TextField sx={{mr: "16px"}}
            margin="normal"
            fullWidth
            id="contactFirstName"
            label="Contact First Name"
            name="contactFirstName"
            />
            <TextField sx={{ml: "16px"}}
            margin="normal"
            fullWidth
            id="contactLastName"
            label="Contact Last Name"
            name="contactLastName"
            />
        </Box>

        <Box sx={{display: "flex", flexDirection: "row", alignItems:"center"}}>
            <TextField sx={{mr: "16px"}}
            margin="normal"
            fullWidth
            id="contactMobile"
            label="Contact Mobile"
            name="contactMobile"
            />
            <TextField sx={{ml: "16px"}}
            margin="normal"
            fullWidth
            id="contactEmail"
            label="Contact Email"
            name="contactEmail"
            />
        </Box>
    </Box>
    );
}