import React from 'react';
import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LanguageIcon from '@mui/icons-material/Language';
import Typography from '@mui/material/Typography';
import Contact from './Contact';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './customer.css'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function Customer() {

    const [contacts, setContacts] = useState([<Contact key={0} />]);

    const handleAddContact = () => {
        setContacts([...contacts, <Contact key={contacts.length} />]);
    };

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    //this function is used to handle form submissions, including sending form data as part of an HTTP POST request with CSRF protection using Axios.
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("Submitting")
    }

  return (
    <ThemeProvider theme={theme}>
      <Box component="main" sx={{ml: "auto", mr: "auto", minWidth: "600px", maxWidth: "800px"}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#09202E' }}>
            <LanguageIcon />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{fontWeight: 600, color: "rgb(51, 51, 51)", fontSize: "1.7rem"}}>
            Master Customer
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

            <Typography sx={{fontSize: "1.5rem", fontWeight: "500", mt: "1rem"}}>Personal Details</Typography>

            <FormControl fullWidth sx={{mt: "16px"}}>
                <InputLabel id="demo-simple-select-label">Sales Rep</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Sales Rep"
                onChange={handleChange}
                >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
                </Select>
            </FormControl>

            <Box sx={{display: "flex", flexDirection: "row", alignItems:"center"}}>
                <TextField sx={{mr: "16px"}}
                margin="normal"
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                />
                <TextField sx={{ml: "16px"}}
                margin="normal"
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                />
            </Box>

            <Box sx={{display: "flex", flexDirection: "row", alignItems:"center"}}>
                <TextField sx={{mr: "16px"}}
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                />
                <TextField sx={{ml: "16px"}}
                margin="normal"
                fullWidth
                id="phoneNumber"
                label="Mobile"
                name="phoneNumber"
                />
            </Box>

            <Typography sx={{fontSize: "1.5rem", fontWeight: "500", mt: "1rem"}}>Address</Typography>

            <TextField
                margin="normal"
                fullWidth
                id="streetAddress"
                label="Address 1"
                name="streetAddress"
            />

            <TextField
                margin="normal"
                fullWidth
                id="streetAddress2"
                label="Address 2"
                name="streetAddress2"
            />

            <Box sx={{display: "flex", flexDirection: "row", alignItems:"center"}}>
                <TextField sx={{mr: "16px"}}
                margin="normal"
                fullWidth
                id="city"
                label="City"
                name="city"
                />
                <TextField sx={{ml: "16px"}}
                margin="normal"
                fullWidth
                id="state"
                label="State"
                name="state"
                />
            </Box>

            <Box sx={{display: "flex", flexDirection: "row", alignItems:"center"}}>
                <TextField sx={{mr: "16px"}}
                margin="normal"
                fullWidth
                id="zipCode"
                label="Zip Code"
                name="zipCode"
                />
                <TextField sx={{ml: "16px"}}
                margin="normal"
                fullWidth
                id="aptNumber"
                label="Apt #"
                name="aptNumber"
                />
            </Box>

            {contacts.map(contact => (
                <Contact key={contact.id} {...contact} />
            ))}

            <button
              style={{marginTop: "16px"}}
              className="submit-button create-account"
              onClick={handleAddContact}
              type='button'
            >
              Add Contact
            </button>


            <Typography sx={{fontSize: "1.5rem", fontWeight: "500", mt: "1rem"}}>Notes</Typography>

            <TextField
            maxRows={5}
            minRows={5}
            inputProps={{ }}
            multiline
            sx={{width: "100%", mb: "24px"}}
            />

            <button
              type="submit"
              className="submit-button"
            >
              Save
            </button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Box>
    </ThemeProvider>
  );
  }  